class ConfigTenant {
  constructor(config) {
    this.version = 2;
    this.tenantCode = config.tenantCode || '';
    this.tenantName = config.tenantName || '';
    this.presetTag = 'export';
    this.gmiServerUrl = process.env.VUE_APP_GMI_SERVER || '';
    this.clientCredentials = config.clientCredentials;
    this.gmiUserManagerUrl = process.env.VUE_APP_USERMANAGER_SERVER || '';
    this.defaultTenantCode = config.tenantCode || '';
    this.defaultRegions = [];
  }
}

export default ConfigTenant;
