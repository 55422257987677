<template>
  <BaseButton
    v-bind="getConfigButton"
    @clicked="getConfig"
    color="accent"
    icon="get_app"
    :disabled="disabled"
  />
</template>

<script>
import FileSaver from 'file-saver';
import RandomUtil from '@/utils/RandomUtil';
import tenantService from '@/services/TenantService';
import credClientService from '@/services/CredClientService';
import baseCredService from '@/services/BaseCredService';
import CredClient from '@/models/CredClient';
import ConfigCredClient from '@/models/ConfigCredClient';
import ConfigTenant from '@/models/ConfigTenant';

export default {
  name: 'TenantConfig',
  props: {
    tenantCode: String,
    tenantName: String,
    disabled: Boolean,
  },
  data: () => ({
    getConfigButton: {
      text: 'Enterprise Config File',
    },
  }),
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    async getConfig() {
      const resp = await tenantService.getConfig(this.tenantCode);
      /* If no config create it */
      if (resp.status === 404) {
        await this.refreshConfig();
      } else {
        const blob = new Blob([resp.data]);
        FileSaver.saveAs(blob, this.tenantCode.concat('.config'));
      }
    },
    async refreshConfig() {
      const clientCred = await this.createCredentials();
      await this.associateRoles(clientCred);
      await this.createConfig(clientCred);
      await this.getConfig();
    },
    async createConfig(clientCred) {
      const configCredClient = new ConfigCredClient({
        clientId: clientCred.clientId,
        clientSecret: clientCred.clientSecret,
      });

      const configTenant = new ConfigTenant({
        tenantCode: this.tenantCode,
        tenantName: this.tenantName,
        clientCredentials: [configCredClient],
      });

      await tenantService.saveConfig(this.tenantCode, configTenant);
    },
    async createCredentials() {
      const clientId = this.tenantCode.concat('GVIDEnt');

      let clientCred = new CredClient({
        clientId,
      });

      const credClientResp = await credClientService.getByClientId(clientId);

      clientCred.clientSecret = RandomUtil.randomPassword(16);

      if (credClientResp.status === 200 || credClientResp.status === 201) {
        /* already exists just update */
        if (credClientResp.data) {
          clientCred.uuid = credClientResp.data.uuid;
        }
        const updatedResp = await credClientService.updateClient(clientCred);
        clientCred = updatedResp.data;
      } else if (credClientResp.status === 404) {
        /* Create new client */
        const createdResp = await credClientService.newClient(clientCred);
        clientCred = createdResp.data;
      }

      return clientCred;
    },
    async associateRoles(clientCred) {
      /* Create a gmi Tenant Admin */
      await baseCredService.associateRole({
        type: 'client',
        tenantCode: this.tenantCode,
        uuid: clientCred.uuid,
        resource: 'gmiserver',
        role: 'ROLE_TENANT_MAINT',
        item: clientCred,
      });

      /* Create a usermanager Tenant Admin */
      await baseCredService.associateRole({
        type: 'client',
        tenantCode: this.tenantCode,
        uuid: clientCred.uuid,
        resource: 'usermanager',
        role: 'ROLE_TENANT_MAINT',
        item: clientCred,
      });
    },
  },
};
</script>
